<template>
  <div class="row">
    <div class="col-md-12" style="background: white">
      <div class="card form-body" style="border:none">
        <div class="card-body">
          <legend>
            <h6>{{ role.title }}</h6>
          </legend>
          <hr />
          <table class="table table-bordered">
            <thead>
              <tr>
                <th class="text-left">
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    v-model="selectAll"
                    @change="selectAllPermissions()"
                  />
                </th>
                <th class="text-left">Permissions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(options, index) in permissions_data" :key="index">
                <td class="text-left">
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    v-model="options.checked"
                  />
                </td>

                <td>{{ options.title }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer">
          <button
            class="btn btn-success ml-3"
            style="float: right"
            @click="update()"
          >
            Update
          </button>

          <button
            class="btn btn-danger mr-3"
            @click="goBack()"
            style="float: right"
          >
            <i class="fa fa-arrow-left"></i> Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import moment from "moment";
import {
  FETCH_ROLE_PERMISSIONS,
  CREATE_ROLE_PERMISSIONS,
  GET_ROLE
} from "@/core/services/store/actions.type";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["currentUser"])
  },
  created() {
    this.breadCrumbs[2].title = "Edit Permissions";
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    // this.getLocationRateLists();
    this.getPermissionData();
  },
  methods: {
    storeUpdate() {
      let data = {
        id: this.$route.params.id,
        data: this.user_permissions
      };
      this.$store
        .dispatch(CREATE_ROLE_PERMISSIONS, data)
        .then(() => {
          this.$toastr.s("Service added successfully");
          this.$store.dispatch(GET_ROLE, this.$route.params.id).then(res => {
            this.role = res;
          });
        })
        .catch(() => {
          this.$toastr.e("oops something went wrong !");
        });
    },
    selectAllPermissions() {
      if (this.selectAll) {
        this.permissions_data.filter(permission => {
          return (permission.checked = true);
        });
      } else {
        this.permissions_data.filter(permission => {
          return (permission.checked = false);
        });
      }
    },
    goBack() {
      this.$router.push({
        name: "client.roles"
      });
    },
    getPermissionData() {
      this.loadingData = true;
      this.$store
        .dispatch(FETCH_ROLE_PERMISSIONS, this.$route.params.id)
        .then(perm_data => {
          perm_data.data.map(permission => {
            // console.log(rate);
            // var key = this.getBServices.

            var key = this.permissions_data.findIndex(
              x => x.title === permission.title
            );
            if (key >= 0) {
              this.permissions_data[key].checked = true;
            }
          });
          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
        });
    },

    update() {
      var table_datas_ = this.permissions_data.filter(
        role_permissions => role_permissions.checked == true
      );
      if (table_datas_.length == 0) {
        this.$toastr.e("Please select at least one permissions");
      } else {
        this.errors = [];
        let _data = {};
        _data.role_id = this.$route.params.id;
        _data.permissions = table_datas_;

        this.$store
          .dispatch(CREATE_ROLE_PERMISSIONS, _data)
          .then(data => {
            this.$toastr.s(data.msg);

            this.$router.push({ name: "client.roles" });
          })
          .catch(err => {
            this.errors = err;
            this.selectAll = false;
            this.serviceError = "";
            this.getPermissionData();
          });
      }
    }
  },
  data() {
    return {
      loadingData: false,
      role: "",
      selectAll: false,
      user_permissions: [],
      permissions_data: [
        {
          title: "Export",
          checked: false
        },
        {
          title: "Login",
          checked: false
        },
        {
          title: "Print",
          checked: false
        }
      ],
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Role List",
          route: "client.roles"
        },
        {
          id: 3,
          title: "Role Permissions",
          route: ""
        }
      ]
    };
  }
};
</script>
